<template>
    <div style="min-height: calc(100vh - 158px);" class="card">
        <div style="background: #DBE7F2" class="p-2">
            <TitleButton
                btnTitle="Add New"
                :showBtn="false"
                :showAddNew="true"
                title="Business List"
                @onClickAddNewButton="onClickAddNewButton"
            />
        </div>
        <ListTable :businesses="businessList"/>
        <div class="mb-2"></div>
        <Loader v-if="loading"/>
    
    </div>
</template>

<script>
import handleCBusinesses from '@/services/modules/businesses'
import {inject} from 'vue'
import {useStore} from 'vuex'

import TitleButton from '@/components/atom/TitleButton'
import ListTable from '@/components/molecule/business/ListTable'
import Pagination from '@/components/atom/Pagination'
import Loader from '@/components/atom/LoaderComponent'

export default {
    name: 'BusinessList',
    
    data: () => ({
        offset: 20,
        businessList: [],
    }),
    
    components: {
        TitleButton,
        ListTable,
        Pagination,
        Loader
    },
    
    computed: {
        companyId() {
            return this.$route.params.companyId
        },
    },
    
    methods: {
        onClickAddNewButton() {
            this.$router.push({
                name: 'CreateBusiness',
                params: {
                    companyId: this.$route.params.companyId,
                    moduleId: this.$route.params.moduleId,
                    menuId: this.$route.params.menuId,
                    pageId: this.$route.params.pageId
                },
            })
        },
        
        async getBusinessList() {
            const query = this.getQuery()
            try {
                this.loading = true
                let res = await this.fetchBusinessList(query)
                if (!res.status) {
                    this.resetPagination()
                    // this.showError(res.message)
                }
                if (res.status) {
                    this.businessList = res.data;
                }
            } catch (err) {
                
                if (err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.loading = false
            }
        },
        
        getQuery() {
            return '?company_id=' + this.companyId
        },
    },
    
    setup() {
        const showError = inject('showError');
        const showSuccess = inject('showSuccess');
        const store = useStore()
        
        const {
            fetchBusinessList,
            loading,
        } = handleCBusinesses()
        
        return {
            fetchBusinessList,
            loading,
            showError,
            showSuccess,
            store
        }
    },
    
    mounted() {
        this.getBusinessList()
    }
}
</script>
